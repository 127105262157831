const middleware = {}

middleware['checkout'] = require('../middleware/checkout.js')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['handleAcceptLanguage'] = require('../middleware/handleAcceptLanguage.ts')
middleware['handleAcceptLanguage'] = middleware['handleAcceptLanguage'].default || middleware['handleAcceptLanguage']

middleware['handleMailParameters'] = require('../middleware/handleMailParameters.ts')
middleware['handleMailParameters'] = middleware['handleMailParameters'].default || middleware['handleMailParameters']

middleware['handleMarket'] = require('../middleware/handleMarket.ts')
middleware['handleMarket'] = middleware['handleMarket'].default || middleware['handleMarket']

middleware['is-authenticated'] = require('../middleware/is-authenticated.js')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['redirect'] = require('../middleware/redirect.ts')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
