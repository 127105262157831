import { integrationPlugin } from '~/helpers/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/core/helpers';
import defaultConfig from '~/modules/core/defaultConfig';
import cookie from '~/modules/core/cookie';

const moduleOptions = JSON.parse('{"i18n":{"useNuxtI18nConfig":true},"cookies":{"currencyCookieName":"vsf-currency","countryCookieName":"vsf-country","localeCookieName":"vsf-locale","cartCookieName":"vsf-cart","customerCookieName":"vsf-customer","storeCookieName":"vsf-store","messageCookieName":"vsf-message","marketCookieName":"vsf-market","wishlistCookieName":"vsf-wishlist","segmentsCookieName":"vsf-segments","acceptLanguageCookieName":"vsf-accept-language"},"externalCheckout":{"enable":false,"cmsUrl":"https://api.gogemini.io/graphql/d286f351-76e0-45cc-b253-1010ee14d348","syncUrlPath":"/vue/cart/sync","stores":{"default":false}},"defaultStore":"en","facets":{"available":["cats_ids","sdc_color_mount","sdc_colore_vetro","sdc_numero_luci","sdc_diametro","sdc_larghezza","sdc_altezza"]},"geminiBaseUrl":"https://www.sognidicristallo.com","shippyPro":{"apiKey":""},"geoLocator":{"enable":"true"},"locales":[{"name":"eu-it"},{"name":"eu-en"},{"name":"eu-es"},{"name":"eu-de"},{"name":"eu-fr"},{"name":"me-en"},{"name":"it-it"},{"name":"fe-en"},{"name":"row-it"},{"name":"row-en"},{"name":"row-es"},{"name":"row-de"},{"name":"row-fr"},{"name":"jp-it"},{"name":"jp-en"},{"name":"jp-es"},{"name":"jp-de"},{"name":"jp-fr"},{"name":"ch-it"},{"name":"ch-en"},{"name":"ch-es"},{"name":"ch-de"},{"name":"ch-fr"},{"name":"ae-it"},{"name":"ae-en"},{"name":"ae-es"},{"name":"ae-de"},{"name":"ae-fr"},{"name":"sa-it"},{"name":"sa-en"},{"name":"sa-es"},{"name":"sa-de"},{"name":"sa-fr"},{"name":"uk-it"},{"name":"uk-en"},{"name":"uk-es"},{"name":"uk-de"},{"name":"uk-fr"}]}');

export default integrationPlugin(({ app, res, req, integration }) => {
  const cartCookieName = moduleOptions.cookies?.cartCookieName || defaultConfig.cookies.cartCookieName;
  const customerCookieName = moduleOptions.cookies?.customerCookieName || defaultConfig.cookies.customerCookieName;
  const storeCookieName = moduleOptions.cookies?.storeCookieName || defaultConfig.cookies.storeCookieName;
  const currencyCookieName = moduleOptions.cookies?.currencyCookieName || defaultConfig.cookies.currencyCookieName;
  const localeCookieName = moduleOptions.cookies?.localeCookieName || defaultConfig.cookies.localeCookieName;
  const countryCookieName = moduleOptions.cookies?.countryCookieName || defaultConfig.cookies.countryCookieName;
  const wishlistCookieName = moduleOptions.cookies?.wishlistCookieName || defaultConfig.cookies.wishlistCookieName;
  const marketCookieName = moduleOptions.cookies?.marketCookieName || defaultConfig.cookies.marketCookieName;
  const segmentsCookieName = moduleOptions.cookies?.segmentsCookieName || defaultConfig.cookies.segmentsCookieName;
  const acceptLanguageCookieName = moduleOptions.cookies?.acceptLanguageCookieName || defaultConfig.cookies.acceptLanguageCookieName;

  const { setCookie, removeCookie, getCookies } = cookie(req, res);
  const setMarket = (grn) => (grn ? setCookie(marketCookieName, grn) : removeCookie(marketCookieName));
  const getMarket = () => getCookies(marketCookieName);
  const getCartId = () => getCookies(cartCookieName);

  const setCartId = (id) => (id ? setCookie(cartCookieName, id) : removeCookie(cartCookieName));

  const getCustomerToken = () => getCookies(customerCookieName);

  const setCustomerToken = (token) => (token ? setCookie(customerCookieName, token) : removeCookie(customerCookieName));

  const getStore = () => getCookies(storeCookieName);

  const setStore = (id) => (id ? setCookie(storeCookieName, id) : removeCookie(storeCookieName));

  const getCurrency = () => getCookies(currencyCookieName);

  const setCurrency = (id) => (id ? setCookie(currencyCookieName, id) : removeCookie(currencyCookieName));

  const getLocale = () => getCookies(localeCookieName);

  const setLocale = (id) => (id ? setCookie(localeCookieName, id) : removeCookie(localeCookieName));

  const getCountry = () => getCookies(countryCookieName);

  const setCountry = (id) => (id ? setCookie(countryCookieName, id) : removeCookie(countryCookieName));

  const setWishlist = (id) => (id ? setCookie(wishlistCookieName, id, { maxAge: 60 * 60 * 24 * 365 }) : removeCookie(wishlistCookieName));

  const getWishlist = () => getCookies(wishlistCookieName);

  const getSegments = (token) => {
    const [checkToken, ids] = (getCookies(segmentsCookieName) || '').split('|');
    return token === checkToken ? ids : [];
  };

  const setSegments = (token, ids) => (ids ? setCookie(segmentsCookieName, `${token}|${ids.join(',')}`) : removeCookie(segmentsCookieName));

  const setAcceptLanguage = (acceptLanguage) =>
    acceptLanguage ? setCookie(acceptLanguageCookieName, acceptLanguage) : removeCookie(acceptLanguageCookieName);
  const getAcceptLanguage = () => getCookies(acceptLanguageCookieName);

  const settings = mapConfigToSetupObject({
    moduleOptions,
    app,
    additionalProperties: {
      state: {
        // Cart
        getCartId,
        setCartId,
        // Customer
        getCustomerToken,
        setCustomerToken,
        // Store
        getStore,
        setStore,
        getCurrency,
        setCurrency,
        getLocale,
        setLocale,
        getCountry,
        setCountry,
        setMarket,
        getMarket,
        // Wishlist
        getWishlist,
        setWishlist,
        setSegments,
        getSegments,
        // Accept Language
        setAcceptLanguage,
        getAcceptLanguage,
      },
    },
  });

  integration.configure('gemini', settings);
});
